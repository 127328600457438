.no-selection {
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/ }

.black-bg {
  background: #252b3b !important; }

.white-color svg,
.white-color span {
  color: white !important; }

.white-icon-color svg {
  color: white !important; }

.collapse-custom div > div > div {
  background-color: transparent !important; }

.svg-icon {
  margin-left: 4px; }
