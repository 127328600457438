/*color name*/
/*color gradient background*/
.my-btn {
  border: none;
  border-radius: 5px;
  padding: 13px 25px;
  font-size: 14px;
  transition: all 100ms ease-out; }
  .my-btn:hover {
    cursor: pointer; }
  .my-btn--main, .my-btn--main:hover {
    color: #fff;
    background: #1cbb8c; }
    .my-btn--main > a, .my-btn--main:hover > a {
      color: #fff; }
  .my-btn--secondary, .my-btn--secondary:hover, .my-btn--secondary:active {
    color: #4f4f4f !important;
    background: linear-gradient(180deg, #f4f5f6 0%, #e4e4e4 100%) !important; }
    .my-btn--secondary > a, .my-btn--secondary:hover > a, .my-btn--secondary:active > a {
      color: #828282 !important; }
  .my-btn--green, .my-btn--green:hover {
    background: linear-gradient(90deg, #4ecd91 0%, #3cb7bf 100%); }
    .my-btn--green > a, .my-btn--green:hover > a {
      color: #fff; }
  .my-btn--transparent, .my-btn--transparent:hover, .my-btn--transparent:active {
    background: white;
    border: 1px solid #ff807a; }
    .my-btn--transparent > a, .my-btn--transparent:hover > a, .my-btn--transparent:active > a {
      color: #ff807a !important; }
  .my-btn a {
    color: white; }
