/*color name*/
/*color gradient background*/
.loader {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms all ease; }
  .loader__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 214px;
    height: 146px;
    box-shadow: 0px 8px 14px rgba(224, 224, 224, 0.5) !important;
    border-radius: 5px !important; }
  .loader__item {
    width: 75px !important;
    height: 75px !important;
    color: #ed5b28 !important; }
