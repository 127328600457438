/*breakpoint*/
.PatternContainer {
  position: relative; }
  .PatternContainer .p-container {
    position: absolute;
    display: none; }
    @media (min-width: 992px) {
      .PatternContainer .p-container {
        display: block; } }
    .PatternContainer .p-container img {
      position: absolute;
      width: 100%;
      height: 100%; }
    .PatternContainer .p-container .bg-pattern {
      position: absolute;
      background: rgba(255, 228, 219, 0.5);
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px; }
  .PatternContainer .content {
    z-index: 2;
    position: relative; }
